import * as React from 'react'
import styled from '@emotion/styled'
// import { Script } from "gatsby"

import Layout from '../components/layout'
import { Seo } from '../components/seo'

// import ResponsiveEmbed from "react-responsive-embed"

import { HeroTasting } from '../components/heros/HeroTasting'
import { SkipUberDelivery } from '../components/SkipUberLogos'

const TastingEvents = () => {
  const Fc = styled.div`
    margin-bottom: var(--space-4);

    h1 {
      margin-bottom: var(--space-1);
    }

    p {
      margin-top: 0;
      padding-top: 0;
    }
  `

  // const CalWrapper = styled.div`
  //   max-width: 1800px;
  //   margin: 0 auto;
  //
  //   display: grid;
  //   grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  //   grid-template-rows: max-content;
  //
  //   .calDay {
  //     display: flex;
  //     flex-direction: column;
  //     align-content: start;
  //     border: 1px solid grey;
  //
  //     margin-bottom: 16px;
  //     .content,
  //     .time {
  //       margin-inline: 16px;
  //     }
  //
  //     .date,
  //     .content,
  //     .time {
  //       margin-top: 0;
  //       padding-top: 0;
  //     }
  //     .date {
  //       padding-inline: 16px;
  //       background: grey;
  //       margin-bottom: 16px;
  //       color: white;
  //       font-weight: bold;
  //     }
  //
  //     .content {
  //       font-weight: bold;
  //     }
  //
  //     .time {
  //       font-weight: bold;
  //     }
  //   }
  // `

  return (
    <Layout>
      <HeroTasting />
      <Fc>
        <h1>Tasting Events</h1>
        <h2 style={{marginBottom: `20vh`}}>We're updating our events, check again soon!</h2>
        {/*<p className="blueFont textCenter"></p>*/}
      </Fc>
      {/*<h2>December, 2022</h2>*/}
      {/*<CalWrapper>*/}
      {/*  {DecCal.map((dates) => {*/}
      {/*    return (*/}
      {/*      <div className="calDay" key={dates.key}>*/}
      {/*        <p className="date">{dates.date}</p>*/}
      {/*        <p className="content">{dates.content}</p>*/}
      {/*        <p className="time">{dates.time}</p>*/}
      {/*      </div>*/}
      {/*    )*/}
      {/*  })}*/}
      {/*</CalWrapper>*/}

      <SkipUberDelivery />
    </Layout>
  )
}

export const Head = () => {
  return (
    <>
      <Seo
        title="Coal Harbour Liquor Store Tasting Events"
        description="Check out our upcoming tasting events!"
        canonical="https://coalharbourliquorstore.com/tasting-events/"
      />
    </>
  )
}

export default TastingEvents

// const DecCal = [
//   { key: 'nov27', date: 'Nov 27', content: '', time: '' },
//   { key: 'nov28', date: 'Nov 28', content: '', time: '' },
//   { key: 'nov29', date: 'Nov 29', content: '', time: '' },
//   { key: 'nov30', date: 'Nov 30', content: '', time: '' },
//   {
//     key: 'dec01',
//     date: 'Dec 01',
//     content: 'Summit Fine Wines Selection',
//     time: '3:30pm-6:30pm',
//   },
//   {
//     key: 'dec02',
//     date: 'Dec 02',
//     content: 'Kettle Valley, Pentage, Emandare',
//     time: ' 4:00pm-7:00pm',
//   },
//   {
//     key: 'dec03',
//     date: 'Dec 03',
//     content: 'Reifel Rye',
//     time: '4:00pm-8:00pm',
//   },
//   { key: 'dec04', date: 'Dec 04', content: '', time: '' },
//   { key: 'dec05', date: 'Dec 05', content: '', time: '' },
//   { key: 'dec06', date: 'Dec 06', content: '', time: '' },
//   { key: 'dec07', date: 'Dec 07', content: '', time: '' },
//   {
//     key: 'dec08',
//     date: 'Dec 08',
//     content: 'Strange Fellows Brewing',
//     time: '3:00pm-7:00pm',
//   },
//   {
//     key: 'dec09',
//     date: 'Dec 09',
//     content: 'Vancouver Island Brewing',
//     time: '3:00pm-6:00pm',
//   },
//   {
//     key: 'dec10',
//     date: 'Dec 10',
//     content: 'On The Rocks Cocktails',
//     time: '4:00pm-8:00pm',
//   },
//   { key: 'dec11', date: 'Dec 11', content: '', time: '' },
//   { key: 'dec12', date: 'Dec 12', content: '', time: '' },
//   { key: 'dec13', date: 'Dec 13', content: '', time: '' },
//   { key: 'dec14', date: 'Dec 14', content: '', time: '' },
//   {
//     key: 'dec15',
//     date: 'Dec 15',
//     content: 'Zuccardi and Santa Julia Wines (Argentina)',
//     time: '3:00pm-6:00pm',
//   },
//   {
//     key: 'dec16',
//     date: 'Dec 16',
//     content: 'Nagging Doubt Winery Kelowna, BC',
//     time: '3:00pm-6:00pm',
//   },
//   {
//     key: 'dec17',
//     date: 'Dec 07',
//     content: 'Patron Tequila',
//     time: '3:00pm-7:00pm',
//   },
//   { key: 'dec18', date: 'Dec 18', content: '', time: '' },
//   { key: 'dec19', date: 'Dec 19', content: '', time: '' },
//   { key: 'dec20', date: 'Dec 20', content: '', time: '' },
//   { key: 'dec21', date: 'Dec 21', content: '', time: '' },
//   {
//     key: 'dec22',
//     date: 'Dec 22',
//     content: 'Spirit Selection from Authentic Wine&Spirits',
//     time: '4:00pm-8:00pm',
//   },
//   {
//     key: 'dec23',
//     date: 'Dec 23',
//     content: 'Hester Creek & Peak Cellars (BC)',
//     time: '4:00pm-7:00pm',
//   },
//   {
//     key: 'dec24',
//     date: 'Dec 24',
//     content: 'Bali Water Canned Cocktails',
//     time: '2:00pm-6:00pm',
//   },
//   { key: 'dec25', date: 'Dec 25', content: '', time: '' },
//   { key: 'dec26', date: 'Dec 26', content: '', time: '' },
//   { key: 'dec27', date: 'Dec 27', content: '', time: '' },
//   { key: 'dec28', date: 'Dec 28', content: '', time: '' },
//   { key: 'dec29', date: 'Dec 29', content: '', time: '' },
//   { key: 'dec30', date: 'Dec 30', content: '', time: '' },
//   { key: 'dec31', date: 'Dec 31', content: '', time: '' },
// ]
